<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t("TreeViews.NewTree", {}, { locale: this.$store.state.activeLang })
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-tree-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t("TreeViews.Name", {}, { locale: this.$store.state.activeLang })
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="treeViewData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "TreeViews.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="FormulaName"
          :disabled="true"
          v-model="treeViewData.formulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="viewFilter" class="form-label required">{{
          $t(
            "TreeViews.ViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditViewFilter/{0}&viewFilterId=',
              this.$route.params.customObjectId
            )
          "
          :data="viewFilterData"
          @onChange="onChangeViewFilter"
          :allowEmpty="false"
          :isParameters="true"
        />
      </div>
      <div class="col col-md-6">
        <label for="headerField" class="form-label">{{
          $t(
            "TreeViews.HeaderField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :isDisabled="
            String.isNullOrWhiteSpace(treeViewData.viewFilterPublicId)
          "
          :data="headerFieldData"
          @onChange="onChangeHeaderField"
          :isParameters="true"
          :resetSelect="selectHeaderFieldReset"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <div class="form-check">
          <label for="IsActive" class="form-label">{{
            $t(
              "BaseModelFields.IsActive",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsActive"
            v-model="treeViewData.isActive"
          />
        </div>
      </div>
    </div>
    <LookupRelationTable
      :data="null"
      :customObjectPublicId="this.$route.params.customObjectId"
      @setTreeItems="getTreeItems"
    />
    <br />
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
</template>
<script>
import $ from "jquery";
import LookupRelationTable from "@/components/custom/tree-view/LookupRelationTable.vue";
export default {
  name: "CustomObjectNewTree",
  components: { LookupRelationTable },
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block d-flex justify-content-center align-items-center"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="ms-2">{0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      treeViewData: {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        viewFilterRequestSize: 100,
        treeItems: [],
        treeViewDepth: 0,
      },
      headerFieldData: [],
      viewFilterData: [],
      isSuccess: false,
      nullObject: [{ key: null, value: null }],
      isDataEmpty: false,
      selectHeaderFieldReset: false,
    };
  },
  methods: {
    onChangeViewFilter: function (selected) {
      this.selectHeaderFieldReset = !this.selectHeaderFieldReset;
      var self = this;
      if (selected.key) {
        this.$prodGatewayAxios
          .get(
            String.format(
              "/Lcdp-SummaryFiltersFields?coId={0}&viewFilterId={1}",
              this.$route.params.customObjectId,
              selected.key
            )
          )
          .then((response) => {
            self.headerFieldData = response.data.items;
          });
      }
      self.treeViewData.viewFilterPublicId = selected.key;
    },
    onChangeHeaderField: function (selected) {
      this.treeViewData.headerFieldPublicId = selected.key;
    },
    getViewFilterData() {
      var self = this;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-ViewFilterListGeneralViewFilters?customObjectPublicId={0}&isAll=false",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          var items = response.data.items;
          items.forEach(function (v) {
            var data = { key: v.publicId, value: v.name };

            self.viewFilterData.push(data);
          });
        });
    },
    getTreeItems(treeItems) {
      this.treeViewData.treeItems = treeItems;
    },
    onClear() {
      this.calendarViewData = {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        icon: "",
        iconColor: "",
        childIcon: "",
        childIconColor: "",
      };

      $(".form-tree-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-tree-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      //this.treeViewData.treeViewDepth = this.treeItems.length;
      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-TreeViewNew", { ...this.treeViewData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/TreeViews/" + this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  watch: {
    "$route.params.customObjectId"() {
      this.treeViewData.customObjectPublicId =
        this.$route.params.customObjectId;
    },
  },
  mounted() {
    this.getViewFilterData();
  },
};
</script>
