<template>
  <div class="mt-3">
    <div class="table-responsive-sm p-0">
      <table class="table table-bordered table-hover table-layout-auto">
        <thead class="table-primary">
          <tr>
            <th width="32" class="text-center">
              {{
                $t(
                  "BaseModelFields.SerialNo",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
            <th class="text-capitalize mw-200">
              {{
                $t(
                  "TreeViews.SubObject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
            <th class="text-capitalize mw-300">
              {{
                $t(
                  "TreeViews.LayerViewField",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in treeItems" :key="item.key">
            <td class="align-middle text-center" ref="index">
              <span class="badge align-middle badge-primary">
                {{ ++index }}
              </span>
            </td>
            <td class="align-middle">
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :id="`lookuprelation_${index - 1}`"
                @onChange="onChangeLookupRelation"
                :isDetailIconShow="true"
                :allowEmpty="false"
                :isParameters="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryLookupRelations?coId={0}',
                    item.customObjectPublicId
                  )
                "
                :selectedData="{
                  key: String.isNullOrWhiteSpace(item.lookupRelationPublicId)
                    ? false
                    : item.lookupRelationPublicId,
                  value: String.isNullOrWhiteSpace(item.lookupRelationName)
                    ? false
                    : item.lookupRelationName,
                }"
              />
            </td>
            <td class="align-middle">
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :id="`viewfilterviewablefields_${index - 1}`"
                @onChange="onChangeLayerViewField"
                :isDetailIconShow="true"
                :resetSelect="item.selectValueReset"
                :allowEmpty="false"
                :isParameters="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryViewFilterViewableFields?coId={0}',
                    item.customObjectPublicIdOfLookupRelation
                  )
                "
                :isDisabled="
                  String.isNullOrWhiteSpace(
                    item.customObjectPublicIdOfLookupRelation
                  )
                    ? true
                    : false
                "
                :selectedData="{
                  key: String.isNullOrWhiteSpace(item.layerViewFieldPublicId)
                    ? false
                    : item.layerViewFieldPublicId,
                  value: String.isNullOrWhiteSpace(item.layerViewFieldName)
                    ? false
                    : item.layerViewFieldName,
                }"
              />
            </td>
            <td>
              <button
                v-if="index == treeItems.length"
                type="button"
                :class="`btn btn-sm btn-success addButton_${index - 1}`"
                @click="addNewLine(index - 1)"
                :disabled="this.buttonDisabled"
              >
                <span>
                  <i
                    :id="`add-icon-${index - 1}`"
                    class="bi bi-plus me-0 ms-0"
                  ></i>
                </span>
              </button>
              <button
                v-if="
                  index == treeItems.length && index - 1 != 0 && !buttonDisabled
                "
                type="button"
                :class="`btn btn-sm btn-danger removeButton_${index - 1}`"
                @click="removeLine(index - 1)"
                :disabled="this.buttonDisabled"
              >
                <span>
                  <i
                    :id="`remove-icon-${index - 1}`"
                    class="bi bi-dash me-0 ms-0"
                  ></i
                ></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "lookupRelationTable",
  emits: ["setTreeItems"],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    customObjectPublicId: {
      type: String,
      default: "",
    },
    isEdit: { type: Boolean, default: false },
  },
  data() {
    return {
      model: {},
      isLoading: false,
      translationItems: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      errors: [],
      treeItems: [],
      treeItemsInactiveList: [],
      treeViewItemData: {
        customObjectId: 0,
        customObjectName: "",
        customObjectPublicId: "",
        customObjectPublicIdOfLookupRelation: "",
        treeViewId: 0,
        treeViewPublicId: "",
        treeViewName: "",
        lookupRelationPublicId: "",
        lookupRelationName: "",
        layerViewFieldPublicId: "",
        layerViewFieldName: "",
        depth: 0,
      },
      buttonDisabled: false,
    };
  },
  methods: {
    onChangeLookupRelation: function (selected, index) {
      if (String.isNullOrWhiteSpace(index)) return;
      index = index.split("_")[1];

      this.treeItems[index].selectValueReset =
        !this.treeItems[index].selectValueReset;
      this.treeItems[index].layerViewFieldPublicId = "";
      this.treeItems[index].layerViewFieldName = "";
      var self = this;
      self.treeItems[index].lookupRelationPublicId = selected.key;
      self.treeItems[index].lookupRelationName = selected.value;
      if (String.isNullOrWhiteSpace(selected.key)) {
        self.treeItems[index].customObjectPublicIdOfLookupRelation = "";
      } else {
        this.treeItems.splice(index + 1, this.treeItems.length);
        this.$prodGatewayAxios
          .get(String.format("/Lcdp-LookupRelationGet?id={0}", selected.key))
          .then((response) => {
            self.treeItems[index].customObjectPublicIdOfLookupRelation =
              response.data.lookupObjectPublicId;
          })
          .catch(function (error) {
            //alert(error);
          });
      }
      this.treeItemsInactiveList = this.treeItems;
      this.$emit(
        "setTreeItems",
        this.treeItemsInactiveList.filter((f) => f.layerViewFieldPublicId != "")
      );
    },
    onChangeLayerViewField: function (selected, index) {
      if (String.isNullOrWhiteSpace(index)) return;
      index = index.split("_")[1];

      if (String.isNullOrWhiteSpace(selected.key)) {
        this.treeItems.splice(index + 1, this.treeItems.length);
      }
      this.treeItems[index].layerViewFieldPublicId = selected.key;
      this.treeItems[index].layerViewFieldName = selected.value;
      this.treeItemsInactiveList = this.treeItems;

      this.$emit(
        "setTreeItems",
        this.treeItemsInactiveList.filter((f) => f.layerViewFieldPublicId != "")
      );
    },
    getObjectRelation(id, index, firstSpan, loadingBlock) {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-LookupRelationGet?id={0}", id))
        .then((response) => {
          var data = {
            customObjectId: 0,
            customObjectName: "",
            customObjectPublicId: response.data.lookupObjectPublicId,
            customObjectPublicIdOfLookupRelation: "",
            treeViewId: 0,
            treeViewPublicId: "",
            treeViewName: "",
            lookupRelationPublicId: "",
            lookupRelationName: "",
            layerViewFieldPublicId: "",
            layerViewFieldName: "",
            depth: 0,
          };
          var button = "";
          this.$prodGatewayAxios
            .get(
              String.format(
                "/Lcdp-SummaryLookupRelations?coId={0}",
                response.data.lookupObjectPublicId
              )
            )
            .then((response) => {
              if (response.data.items.length > 0) {
                this.treeItems.push(data);
                // button = $(String.format(".addButton_{0}", index));
                // button.show();
                this.success(firstSpan, loadingBlock);
              } else {
                this.error(firstSpan, loadingBlock);
                createToast(
                  this.$t(
                    "TreeViews.LookupRelationWarningMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
                  {
                    showIcon: "true",
                    position: "top-right",
                    type: "warning",
                    transition: "zoom",
                    hideProgressBar: true,
                  }
                );
                // if (index != 0) {
                //   button = $(String.format(".addButton_{0}", index));
                //   button.hide();
                // }
              }
            })
            .catch(function (error) {
              //alert(error);
            });
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    addNewLine(index) {
      var currentLine = this.treeItems[index];

      if (!currentLine) return;

      if (String.isNullOrWhiteSpace(currentLine.layerViewFieldPublicId)) {
        createToast(
          this.$t(
            "TreeViews.LayerViewWarningMessage",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "warning",
            transition: "zoom",
            hideProgressBar: true,
          }
        );
        return;
      }

      this.buttonDisabled = true;

      var button = $(String.format(".addButton_{0}", index)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      this.getObjectRelation(
        currentLine.lookupRelationPublicId,
        index,
        firstSpan,
        loadingBlock
      );
      this.treeItemsInactiveList = this.treeItems;
      this.$emit(
        "setTreeItems",
        this.treeItemsInactiveList.filter(
          (f) => !String.isNullOrWhiteSpace(f.layerViewFieldPublicId)
        )
      );
    },
    removeLine(index) {
      this.treeItems.pop();
    },
    success: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
    },
    error: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
    },
  },
  mounted() {
    if (this.data == null) {
      this.treeViewItemData.customObjectPublicId =
        this.$route.params.customObjectId;
      this.treeItems.push(this.treeViewItemData);
    } else {
      this.treeItems = this.data.treeItems;
    }
  },
};
</script>
